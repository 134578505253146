.side-drawer {
    height: 100%;
    background: rgba(13, 11, 33, .92);
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    max-width: 220px;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    z-index: 200;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer.closed {
    transform: translateX(-100%);
}

.side-drawer a {
    font-size: 1.1rem;
    color: #fff;
    text-decoration: none;
}

.side-drawer a:hover {
    color: rgb(255, 164, 27);
    text-decoration: none;
}

.side-drawer a:active {
    color: rgb(255, 102, 0)
}

.side-drawer ul {
    height: 50vh;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 200px 20px 0;
    text-align: right;
}

.side-drawer li {
    margin: 20px 0;
}

@media screen and (min-width: 862px) {
    .side-drawer {
        display: none;
    }
}