.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button_line {
    width: 30px;
    height: 2px;
    background: black;
}