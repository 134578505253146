/* Main App */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App_Container {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

h2 {
  text-align: left;
  font-weight: 700;
}

.container {
  background-color: #0D0B21;
  padding: 2rem;
  height: 100%;
  color: white;
  text-align: center;
}

.containerWhite {
  background-color: white;
  padding: 2rem;
  height: 100%;
  color: #0D0B21;
  text-align: center;
}

.containerGold {
  background-color: #62481E;
  padding: 2rem;
  height: 100%;
  color: white;
  text-align: center;
}

.subhead {
  font-size: 1.8rem;
}

.sectionMainImage {
  border: 1px solid white;
  margin-top: 1rem;
  width: 100%;
  max-width: 1024px;
}

.whiteSectionMainImage {
  margin-top: 1rem;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
}

.sectionMainImageCaption {
  padding: .5rem 0 1rem;
  margin: 0 auto;
  max-width: 1024px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}

/* NavBar Section */

.toolbar {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
  height: 100px;
  padding: 0 20px;
  z-index: 600;
}

.toolbar_navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.toolbar_toggle-button {
  display: none;
}

.toolbar_logo {
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.logoImage {
  background: url(https://foundationsincreations.s3.amazonaws.com/ficLogo_nav.png) no-repeat top center;
  background-size: contain;
  width: 58px;
  height: 80px;
  margin-left: 10px;
}

.logoType {
  font-size: 2.7rem;
  font-weight: 300;
  margin-left: 1rem;
  letter-spacing: 1px;
  line-height: 40px;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  width: 300px;
}

.toolbar_navigation_items .item {
  font-size: .9rem;
  font-weight: 300;
  color: black;
  text-decoration: none;
  padding: 10px;
}

.toolbar_navigation_items .item:hover {
  color: rgb(255, 164, 27)
}

.toolbar_navigation_items .item:active {
  color: rgb(255, 102, 0)
}

.social {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.fab {
  color: black;
  margin-left: 30px;
  margin-bottom: 20px;
}

.fab:hover {
  color: rgb(255, 164, 27)
}

.fab:active {
  color: rgb(255, 102, 0)
}

/* Slider */

.mainSlider {
  padding-top: 100px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  color: white;
  cursor: pointer;
}

.slide_1 .slide_headline {
  position: absolute;
  width: 550px;
  top: 320px;
  left: 30px;
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: 700;
  text-shadow: 3px 3px 3px black;
}

.slide_1 .slide_text {
  position: absolute;
  width: 650px;
  top: 500px;
  left: 30px;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 2px 2px 2px black;
}

.slide_2 .slide_headline {
  position: absolute;
  width: 650px;
  top: 50px;
  right: 30px;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  text-shadow: 3px 3px 3px black;
  text-align: right;
}

.slide_2 .slide_text {
  position: absolute;
  width: 450px;
  top: 170px;
  right: 30px;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 2px 2px 2px black;
  text-align: right;
}

.slide_3 .slide_headline {
  position: absolute;
  width: 500px;
  top: 50px;
  right: 30px;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: right;
  font-weight: 700;
  text-shadow: 3px 3px 3px black;
}

.slide_3 .slide_text {
  position: absolute;
  width: 480px;
  top: 170px;
  right: 30px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;
  text-shadow: 2px 2px 2px black;
}

.missionStatement {
  margin: 2rem auto;
  padding: 0 2rem;
  width: 100%;
  max-width: 1024px;
  font-style: italic;
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: center;
  text-transform: uppercase;
}

/*About Section */

.aboutUs, .bioText {
  padding: .9rem 0 .1rem;
  margin: 0 auto;
  max-width: 1024px;
  text-align: left;
  font-weight: 300;
  font-size: 1rem;
}

.aboutUsIndent {
  padding: .9rem 15% .1rem 10%;
  margin: 0 auto;
  max-width: 1024px;
  text-align: left;
  font-weight: 300;
  font-size: 1rem;
}

.speechList, .speechList2 {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
}

.moreText {
  display: none;
}

li {
  list-style: none;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img225, .img325 {
  border: 1px solid white;
  box-shadow: 0 4px 8px aliceblue;
  width: 320px;
}

.img225-white {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
  width: 320px;
}

.imgText {
  text-align: center;
  width: 350px;
  font-size: 1rem;
  padding: 1rem 2rem 3rem 1rem;
}

.bioContainer {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding-top: 2.5rem;
}

.bioImage1, .bioImage2 {
  float: left;
  width: 10rem;
  margin: .5rem 1rem 1rem 0;
  border: 1px solid white;
}

.bio1 .bioText, .bio2 .bioText {
  margin-right: 2.5rem;
  line-height: 1.6rem;
}

.bioText {
  padding: 0 20px 0 0;
}

.bioText p {
  padding: 0 0 1rem 0;
}

/*Contact Us - Footer Section */

.footer {
  background-color: #0D0B21;
  padding: 2rem;
  height: 100%;
  color: white;
  text-align: left;
}

.contactContainer {
  display: flex;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.contact1, .contact2 {
  width: 100%;
  max-width: 511px;
  margin-left: 2rem;
  padding-bottom: 2rem;
}

.contactText a {
  color: white;
}

.contactSocial {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
}

.contactSocial .fab {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  ;
}

.copyright {
  font-size: .75rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (min-width: 801px) and (max-width: 1023px) {
  /*NavBar Section */
  .toolbar {
    height: 90px;
    position: fixed;
  }
  .logoImage {
    height: 58px;
    width: 42px;
    margin-left: 0px;
  }
  .logoType {
    font-size: 2rem;
    margin-left: .5rem;
    letter-spacing: 0;
    line-height: 30px;
    width: 200px;
  }
  .toolbar_navigation_items .item {
    font-size: .7rem;
    font-weight: 300;
    color: black;
    text-decoration: none;
    padding: 12px;
    padding-bottom: 10px;
  }
  .fab {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  /*Slider Section */
  .mainSlider {
    width: 100%;
    padding-top: 90px;
    margin: 0 auto;
    color: white;
  }
  .slide_1 img, .slide_2 img, .slide_3 img {
    width: 100%;
  }
  .slide_1 .slide_headline {
    position: absolute;
    width: 500px;
    top: 50px;
    left: 60px;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-shadow: 3px 3px 3px black;
  }
  .slide_1 .slide_text {
    position: absolute;
    width: 500px;
    top: 185px;
    left: 60px;
    font-size: 1.125rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
  }
  .slide_2 .slide_headline {
    position: absolute;
    width: 500px;
    top: 50px;
    right: 60px;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-shadow: 3px 3px 3px black;
    text-align: right;
  }
  .slide_2 .slide_text {
    position: absolute;
    width: 380px;
    top: 135px;
    right: 60px;
    font-size: 1.125rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
    text-align: right;
  }
  .slide_3 .slide_headline {
    position: absolute;
    width: 400px;
    top: 50px;
    right: 60px;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-shadow: 3px 3px 3px black;
    text-align: right;
  }
  .slide_3 .slide_text {
    position: absolute;
    width: 380px;
    top: 135px;
    right: 60px;
    font-size: 1.125rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
    text-align: right;
  }
  /*About Us Section */
  .aboutUs, .aboutUsIndent {
    width: 100%;
    max-width: 1023px;
    font-size: .6rem;
  }
  .sectionMainImageCaption {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 800px) {
  /* Main App */
  .subhead {
    font-size: 1.375rem;
  }
  .sectionMainImageCaption {
    padding: .85rem 0 .25rem;
    width: 300px;
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: .5rem;
  }
  .speechList {
    /* cursor: pointer; */
    height: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .speechList:hover {
    height: auto;
  }
  .containerWhite .speechList, .containerGold .speechList {
    height: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .imgContainer.showMobile {
    align-items: center;
  }
  .containerWhite .speechList:hover, .containerGold .speechList:hover {
    height: auto;
  }
  .moreText {
    display: block;
    text-align: right;
    margin-right: 20px;
    font-size: .85rem;
    cursor: pointer;
    padding-bottom: 10px;
  }
  .img325, .img225, .img225-white {
    display: none;
  }
  .img225-white.showMobile {
    display: flex;
  }
  .imgText {
    text-align: left;
    margin-left: 2rem;
    padding-bottom: 1rem;
  }
  .imgText.showMobile {
    text-align: center;
  }
  /*NavBar Section */
  .toolbar {
    position: fixed;
    height: 70px;
    display: flex;
    background: rgb(255, 255, 255);
  }
  .toolbar_logo {
    display: flex;
    align-items: center;
  }
  .logoImage {
    height: 50px;
    width: 37px;
    margin-left: 0px;
  }
  .logoType {
    font-size: 28px;
    margin-left: 10px;
    letter-spacing: 0;
    line-height: 28px;
    width: 200px;
  }
  .spacer {
    display: none
  }
  .toolbar_toggle-button {
    display: flex;
    position: absolute;
    right: 20px;
  }
  .toolbar_navigation_items {
    display: none;
  }
  /*Slider Section */
  .mainSlider {
    padding-top: 70px;
    max-width: 100%;
  }
  .missionStatement {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6rem;
    padding: 0 2rem;
  }
  .slide_1 img, .slide_2 img, .slide_3 img {
    width: 100%;
  }
  .slide_1 .slide_headline {
    position: absolute;
    width: 320px;
    top: 20px;
    left: 20px;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-shadow: 3px 3px 3px black;
  }
  .slide_1 .slide_text {
    position: absolute;
    width: 280px;
    top: 110px;
    left: 20px;
    font-size: .95rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
  }
  .slide_2 .slide_headline {
    position: absolute;
    width: 320px;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-shadow: 3px 3px 3px black;
    text-align: right;
  }
  .slide_2 .slide_text {
    position: absolute;
    width: 300px;
    top: 80px;
    right: 25px;
    font-size: .95rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
    text-align: right;
  }
  .slide_3 .slide_headline {
    position: absolute;
    width: 320px;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-shadow: 3px 3px 3px black;
    text-align: right;
  }
  .slide_3 .slide_text {
    position: absolute;
    width: 320px;
    top: 80px;
    bottom: 250px;
    font-size: .95rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px black;
    text-align: right;
  }
  /* About Us Section */
  .aboutUs, .bioText {
    width: 100%;
    max-width: 800px;
  }
  .bio1 .bioText {
    margin-right: 2.5rem;
  }
  .bioContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .bio1 .bio2 {
    width: 100%;
    padding: .9rem 0 .1rem 1.5rem;
  }
  .bioImage1 {
    width: 9rem;
    margin-right: 1rem;
  }
  .bioImage2 {
    float: right;
    width: 9rem;
    padding-right: 0rem;
    margin-left: 1rem;
  }
  /* Contact Us Section */
  .contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
  }
  .contactSocial {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }
  .contactSocial .fab {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    ;
  }
  .contact1, .contact2 {
    padding: .25rem 0 1rem 1rem;
  }
  .contactText p {
    margin: 1rem 0 -1rem 0;
  }
  .copyright {
    margin-bottom: 1rem;
  }
}