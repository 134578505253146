.upArrow {
    position: fixed;
    width: 40px;
    bottom: 30px;
    right: 40px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.75;
    color: rgb(116, 116, 116);
}

.upArrow:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.2;
    }
}